import React from "react";
import { Row, Col, Layout, Button } from "antd";
import PublicLayout from "../components/PublicLayout";
import moment from "moment";
import { Link, graphql } from "gatsby";
import "moment/locale/es";
import SEO from "../components/seo";
import imageUrlReplace from "./components/blog/imageUrlReplace";
import ExamTabs from "./components/exam/examTabs";
import { useTranslation } from "react-i18next";

moment.locale("es")

const { Content } = Layout

const Exam = ({ pageContext }) => {
  const {
    id,
    title,
    fields: {
      excerpt,
      image: { sourceUrl, altText },
      content,
      exams,
      duration,
      requirements,
      associatedRisks,
      resolution,
      examValidity,
      metaTitle,
      metaDescription,
      priceSchema
    },
  } = pageContext;
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Product",
    "brand": {
      "@type": "Brand",
      "name": "CMT Salud",
    },
    "offers": {
      "price": priceSchema && priceSchema,
    },
  }];
  

  return (
    <PublicLayout>
      <SEO
        title={metaTitle && metaTitle}
        description={metaDescription && metaDescription}
        image={imageUrlReplace(sourceUrl && sourceUrl)}
        dataLayer={{
          userId: "",
          pageId: id && id,
          pageType: "project",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className="exam-page-container">
        <Content>
          <Row justify='center'>
            <Col lg={24} xs={24} className="image-title-container">
              <Row justify="center">
                <Col lg={24} xs={24}>
                  <div
                    style={{
                      backgroundImage: `url('${imageUrlReplace(sourceUrl)}')`,
                    }}
                    title={altText && altText}
                    className="image"
                  >
                    <Row className="text-button-wrapper">
                      <Col lg={12} xs={22}>
                        <Row justify="end">
                          <Col xl={19} lg={21} xs={24} className="card-container">
                            <Row>
                              <Col lg={24} xs={24}>
                                <h1 className="title">{title}</h1>
                              </Col>
                              <Col lg={24} xs={24}>
                                <h2 dangerouslySetInnerHTML={{ __html: excerpt }} />
                              </Col>
                              <Col>
                                <Link to="/app/reservar/">
                                  <Button
                                    className="cmt-button secondary"
                                    type="primary"
                                    size="large"
                                  >
                                    Agenda aquí
                                  </Button>
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={19} xs={22} className="content-tabs-container mt-lg-60 mb-lg-100 mb-md-40 mb-sm-40 mb-xs-40">
              <Row justify="center">
                <Col lg={22} xs={22} dangerouslySetInnerHTML={{ __html: content && content }} />
                <Col lg={24} xs={24} className="mt-lg-80 mt-md-30 mt-sm-30 mt-xs-30 mb-lg-60 mb-md-30 mb-sm-30 mb-xs-30">
                  <ExamTabs
                    exams={exams && exams}
                    duration={duration && duration}
                    resolution={resolution && resolution}
                    examValidity={examValidity && examValidity}
                    requirements={requirements && requirements}
                    associatedRisks={associatedRisks && associatedRisks}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Row>
                    <Col xl={4} lg={6} xs={24}>
                      <Link to={countryCode === 'CL' ? `/examenes-preocupacionales-ocupacionales/` : `/examenes-medicos-ocupacionales/`}>
                        <Button className="cmt-button primary" type="primary" size="large">
                          {t('exam.buttonBatteries')}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default Exam;

export const postQuery = graphql`
  query GET_LOCALES($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;