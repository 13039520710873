import React from "react";
import { Row, Col, Tabs, Collapse } from 'antd';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const ExamTabs = ({
  exams,
  duration,
  resolution,
  examValidity,
  requirements,
  associatedRisks }) => {

  const durationContent = (
    <Col lg={24} xs={24}>
      <Row
        align="middle"
        gutter={5}
        className={(resolution || examValidity) ? "duration-wrapper mb-lg-40 mb-md-20 mb-sm-20 mb-xs-20" : "duration-wrapper mb-0"}
      >
        <Col>
          <span className="icon-clock-time" title="Icono Reloj" />
        </Col>
        <Col>
          <p className="mb-0">
            <strong>Duración de la evaluación:</strong> <italic>{duration}</italic>
          </p>
        </Col>
      </Row>
      {
        examValidity && 
          <Row>
            <Col lg={24} xs={24}>
              <p className="mb-20 validity-text">
                <strong>Validez de examen:</strong>&nbsp; {examValidity}
              </p>
            </Col>
          </Row>
      }
      {
        resolution &&
          <Row>
            <Col lg={24} xs={24}>
              <p className="mb-0 resolution-text">{resolution}</p>
            </Col>
          </Row>
      }
    </Col>
  );

  const tabPaneContent = [
    {
      key: '1',
      title: 'Labores con exposición a este riesgo',
      icon: 'icon-alert-triangle',
      iconTitle: 'Icono Triángulo de alerta',
      content: (
        <Row justify="center">
          <Col
            lg={24} xs={24}
            dangerouslySetInnerHTML={{ __html: associatedRisks }}
          />
          {durationContent}
        </Row>
      ),
    },
    {
      key: '2',
      title: 'Exámenes',
      icon: 'icon-documents',
      iconTitle: 'Icono Papeles apilados',
      content: (
        <Row justify="center">
          <Col
            lg={24} xs={24}
            dangerouslySetInnerHTML={{ __html: exams }}
          />
          {durationContent}
        </Row>
      ),
    },
    {
      key: '3',
      title: 'Requisitos',
      icon: 'icon-document-lens',
      iconTitle: 'Icono Lupa sobre papeles',
      content: (
        <Row justify="center">
          <Col
            lg={24} xs={24}
            dangerouslySetInnerHTML={{ __html: requirements }}
          />
          {durationContent}
        </Row>
      ),
    },
  ];


  return (
    <Row justify="center" className="exam-tabs-wrapper">
      <Col lg={24} xs={24} className="tabs-wrapper">
        <Tabs defaultActiveKey="1" className="custom-tab">
        {
          tabPaneContent.map((tabPane) => {
            return (
              <TabPane
                key={tabPane.key}
                tab={
                  <Row className="title-tab-wrapper" align="middle" gutter={5}>
                    <Col className="icon-container">
                      <span className={tabPane.icon} title={tabPane.iconTitle} />
                    </Col>
                    <Col>
                      <p className="mb-0">
                        {tabPane.title}
                      </p>
                    </Col>
                  </Row>
                }
              >
                {tabPane.content}
              </TabPane>
            )
          })
        }
        </Tabs>
      </Col>
      <Col lg={24} xs={24} className="collapse-wrapper">
        <Collapse
          accordion
          expandIconPosition='end'
          defaultActiveKey='1'
        >
        {
          tabPaneContent.map((tabPane) => {
            return (
              <Panel
                key={tabPane.key}
                header={
                  <Row className="title-tab-wrapper" align="middle" gutter={5}>
                    <Col className="icon-container">
                      <span className={tabPane.icon} title={tabPane.iconTitle} />
                    </Col>
                    <Col>
                      <p className="mb-0">
                        {tabPane.title}
                      </p>
                    </Col>
                  </Row>
                }
              >
                {tabPane.content}
              </Panel>
            )
          })
        }
        </Collapse>
      </Col>
    </Row>
  );
};

export default ExamTabs;